import * as Sentry from '@sentry/browser';

import { ApplicationConfiguration } from './application';

export default (configuration: ApplicationConfiguration): void => {
  if (
    DEPLOYED &&
    configuration.environment !== 'development' &&
    configuration.environment !== 'local'
  ) {
    Sentry.init({
      dsn: configuration.sentryDsn as string,
      environment: configuration.environment as string,
    });
  }
};
