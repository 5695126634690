import {
  ApplicationEventBus,
  AuthenticationContext,
  StandardEvents,
} from '@upside/spa-shared-context';

const LOCALE_TO_LANG_CODE = {
  'en-gb': 'en',
  'ja-jp': 'ja',
} as const;

export const convertLocaleToLangCode = (localeString: string): LangCode => {
  const fallback = LOCALE_TO_LANG_CODE['en-gb'];

  const normalisedLocaleString =
    localeString?.toLowerCase?.() as LowerCaseLocale;

  const langCode = LOCALE_TO_LANG_CODE[normalisedLocaleString];

  if (!localeString || !langCode) {
    return fallback;
  }

  return langCode;
};

type LowerCaseLocale = keyof typeof LOCALE_TO_LANG_CODE;

type LangCode = (typeof LOCALE_TO_LANG_CODE)[LowerCaseLocale];

/**
 * Set the language based on the user's locale from a token retrieved from Cognito when login event received
 * @param authentication - The authentication context of type AuthenticationContext
 * @param eventBus - The event bus of type ApplicationEventBus
 */
export const loginSetLanguage = async (
  authentication: AuthenticationContext,
  eventBus: ApplicationEventBus,
): Promise<void> => {
  try {
    const locale = await authentication?.locale();

    if (!locale) {
      return;
    }

    const langCode = convertLocaleToLangCode(locale);

    eventBus?.emit(StandardEvents.ChangeLanguage, langCode);
  } catch {
    console.error('Failed to set language from locale');
  }
};
