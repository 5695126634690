import { ApplicationContext } from '@upside/spa-shared-context';

import { start } from 'single-spa';

import { paths } from './const';

export default (context: ApplicationContext): void => {
  if (
    !context.authentication.loggedIn &&
    !window.location.pathname.startsWith(`/${paths.LOGIN}`)
  ) {
    const redirectTo = `${window.location.pathname}${
      window.location.search ?? ''
    }`;
    const redirect = new URLSearchParams({ redirectTo });
    window.location.replace(`/${paths.LOGIN}?${redirect.toString()}`);
  } else if (
    context.authentication.loggedIn &&
    window.location.pathname.startsWith(`/${paths.LOGIN}`)
  ) {
    window.location.replace('/');
  } else {
    start();
  }
};
