import { ApplicationContext } from '@upside/spa-shared-context';

import { paths } from 'shell/const';
import { registerApplication } from 'single-spa';

export default (context: ApplicationContext): void => {
  registerApplication<ApplicationContext>({
    name: 'logout',
    app: {
      bootstrap: () => Promise.resolve(),
      mount: async () => context.authentication.logout(),
      unmount: () => Promise.resolve(),
    },
    activeWhen: (location) => location.pathname.startsWith(`/${paths.LOGOUT}`),
  });
};
