import { StandardEvents } from '@upside/spa-shared-context';

import registerEventHandlers from 'event-handlers';

import createApplicationContext from './application-context';
import initialiseAnalytics from './configuration/analytics';
import getApplicationConfiguration from './configuration/application';
import initialiseSentry from './configuration/sentry';
import { loginSetLanguage } from './configuration/set-language';
import configureSingleSpa from './configuration/single-spa';
import startShell from './start-shell';

export default async (): Promise<void> => {
  // While awaiting the configuration and auth responses, show a basic loading screen
  document.body.innerHTML = `<div
    id="initial-loading"
    style="
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      fill: hsl(var(--hsl-button-primary));
      position: relative;
    "
  >
    <div
      style="
        position: absolute;
        top: 33%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <svg
        class="animate-spin"
        width="40"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"
        />
      </svg>
    </div>
  </div>
  `;

  const configuration = await getApplicationConfiguration();
  const context = await createApplicationContext(configuration);
  const { authentication, eventBus } = context;

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  eventBus.on(StandardEvents.Login, async () =>
    loginSetLanguage(authentication, eventBus),
  );

  initialiseAnalytics(configuration);
  initialiseSentry(configuration);

  registerEventHandlers(context);

  configureSingleSpa(context);

  startShell(context);

  document.getElementById('initial-loading')?.remove();
};
