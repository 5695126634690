import { paths } from 'shell/const';
import { navigateToUrl } from 'single-spa';

const DEFAULT_PATH = `/`;

function validateRedirect(redirect: string): string {
  if (/^(?:http|json|data|\/\/)/.test(redirect)) {
    return DEFAULT_PATH;
  }

  return redirect;
}

export function handleLogoutEvent(): void {
  navigateToUrl(`/${paths.LOGIN}`);
}

export function handleLoginEvent(): void {
  const urlParameters = new URLSearchParams(window.location.search);

  if (urlParameters.has('redirectTo')) {
    const redirectTo: string = urlParameters.get('redirectTo') as string;
    navigateToUrl(validateRedirect(redirectTo));
  } else if (window.location.pathname.startsWith(`/${paths.LOGIN}`)) {
    navigateToUrl(DEFAULT_PATH);
  }
}

export function handleRoutingEvent(newUrl: string, isLoggedIn: boolean) {
  if (isLoggedIn && newUrl.includes('/login')) {
    navigateToUrl(paths.BASE);
  }
}
