import { ApplicationContext } from '@upside/spa-shared-context';

import { appHeaderAppName, basePaths, navigationAppName } from 'shell/const';
import { registerApplication } from 'single-spa';
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from 'single-spa-layout';

import registerLogout from './logout';

/* eslint-disable @typescript-eslint/no-var-requires */
const appHeaderError = require('./app-header-error.html');
const contentAppError = require('./content-app-error.html');
const contentAppLoader = require('./content-app-loader.html');
const navError = require('./nav-error.html');

export default (context: ApplicationContext) => {
  const layoutRoot = document.querySelector('#single-spa-layout');

  if (!layoutRoot) {
    throw new Error('Could not find template element');
  }

  const routes = constructRoutes(layoutRoot, {
    errors: {
      appHeaderError,
      contentAppError,
      navError,
    },
    loaders: {
      contentAppLoader,
    },
    props: {},
  });

  const applications = constructApplications({
    routes,
    loadApp: ({ name }: any) => System.import(name),
  });

  constructLayoutEngine({
    routes,
    applications,
  });

  const activeWhenLoggedIn = [appHeaderAppName];
  const registerApplicationConfigs = applications.map((application) => {
    let activeWhen = activeWhenLoggedIn.includes(application.name)
      ? () => context.authentication.loggedIn
      : application.activeWhen;

    if (application.name === navigationAppName) {
      activeWhen = (location) =>
        // Show the navigation app when the user is logged in and not on the domestic dashboard
        context.authentication.loggedIn &&
        !location.pathname.startsWith('/domestic-dashboard');
    }

    return {
      ...application,
      activeWhen,
      customProps: {
        ...application.customProps,
        basePath: basePaths[application.name],
        context,
      },
    };
  });

  registerApplicationConfigs.forEach(registerApplication);

  // Logout isn't a single-spa app, so can't be declared in the template
  registerLogout(context);
};
